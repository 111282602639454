<template>
    <div>
		<v-container style="display: contents;">
			<v-row>
				<div class="col-md-12 col-sm-12">
            <v-card outlined class="pa-md-4 mx-lg-auto">
                <s-toolbar
                    label="Detalle de Pallet"
                    dark 
                    close 
                    @close="closeDetallePalletInPosition()"
                :color="'#BAB6B5'"
                    style="display: flex"
                    print
                    @print="printQR()"
                >
                </s-toolbar>
                <v-card-text>
                    
                    <v-row  v-for="(item, pos) in tunnelInfo.Pallets" :key="pos">
                        <v-col>
                            <v-row>
                                <v-col >
                                    <div >
                                        <b >ID Pallet : </b><v-chip class="ma-2" color="green" outlined><v-icon left> fa-pallet </v-icon>{{item.PalletGroupID}} </v-chip>
                                    </div>
                                    <div hidden>
                                        <vue-qr-generator ref="qrGenerator" :text="QrData.toString()" :size="200"></vue-qr-generator>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>                            
                                    <v-card-text class="text-center">
                                        <b>Cultivo : </b> {{item.TypeCultiveName}} - {{ item.VrtDescription }}<br>
                                        <b>Cliente : </b> {{item.CumNombre }}<br>
                                        <b>Tipo Corte : </b> {{item.TypeCutName }}<br>
                                        <b>Presentacion : </b> {{item.TypePresentationName }}<br>
                                        <b>{{ 'N° Cajas : '+ item.NumberBoxes }}</b>
                                        <h4>{{ ' Peso (Kg) : '+ item.WeightNetBoxes }}</h4>
                                        <h4>{{ ' ID Trazabilidad : '+ item.TraceabilityID }}</h4>                                
                                        <h4 v-if="item.Lote != null">{{ ' Lote: '+ item.Lote }}</h4>
                                    </v-card-text>                            
                                </v-col>
                            </v-row>
                            <v-row align="center" justify="center" style="margin:auto">
                                <v-col cols="auto" v-for="(col,j) in item.palletDetailCEs" :key="j">
                                    
                                    <v-card theme="dark" hover>                                
                                        <s-toolbar :color="col.Temporal === 1 ? 'yellow': '#5edf87'"  height="30" dark prominent rounded>
                                            
                                            <v-spacer></v-spacer>
                                                
                                            <v-btn	v-if="col.Observation"                                                                    
                                                fab
                                                small
                                                color="red">
                                                OB
                                            </v-btn>						 
                                            
                                        </s-toolbar>
                                        <v-card-text>
                                            <b>{{ 'Cultivo : '+  col.TypeCultiveName + ' ' + col.VrtDescription }}</b><br/>
                                            <b>{{ 'Marca : '+ item.BransName }}</b><br/>
                                            <b>{{ 'N° Cajas : '+ col.TotalBox }}</b><br/>                                    
                                            <b>{{ 'Peso (Kg) : '+ col.TotalBagWeight }}</b><br/>
                                            <b>{{ 'ID Trazabilidad : '+ col.TraceabilityID }}</b><br/>
                                            <!-- <b>{{ 'RcfIDs : '+ col.RcfIDs }}</b><br/> -->
                                            <b>{{ 'Presentacion : '+ col.TypePresentationName }}</b><br/>   
                                            <b>{{ 'Corte : '+ col.TypeCutName }}</b><br/>
                                            <!-- <b>{{ ' Variante : '+ col.TypeVariantString }}</b><br/>
                                            <b>{{ ' Tipo Produccion : '+ col.TypeProductiontString }}</b><br/> -->
                                            <b>{{ 'E. Final : '+ col.StateFinalString }}</b><br/>
                                            <b v-if="col.Observation != null">{{ 'Observación : '+ col.Observation }}</b>
                                            <b v-if="col.PalletLocationActual != null">{{ 'Observación : '+ col.PalletLocationActual }}</b>
                                            
                                        </v-card-text>
                                    </v-card>                         
                                </v-col>
                            </v-row>                
                        </v-col>
                    </v-row>
                </v-card-text>                            
            </v-card> 
        </div>
    </v-row>
</v-container>
</div>       
</template>
<script>
    import _sPackingTunnel from '@/services/FrozenProduction/FrzChamberDispatchTunnels.js';
    import VueQrGenerator  from "vue-qr-generator";
   

    export default{
        components: {VueQrGenerator },
        props:{
            sider:{
                type : Number,
                required: true
            },
            lane:{
                type : Number,
                required : true,
            },
            level:{
                type : Number,
                required : true,
            },
            pst : {
                type : Number,
                required : true , 
            },
            CdtID : {
                type : Number,
                required : true,
            }
        },
        data(){
            return {
                tunnelInfo: [],
                tunnelInfo: {
                    Pallets:{
                        PalletsGroups: []
                    }
                },
                executeQR: false,
                showMore: false,
                QrData: String,
                // PalletsGroups:{}
                // tunnelInfo :{
                //     Pallets:{
                //         PalletsGroups: []
                //     }
                    
                // },
            }
        },
        methods: {
            closeDetallePalletInPosition(){
                 
                this.$emit('closeDetallePalletInPosition');
            },
            detailPallet(){
                let data = {
                    CdtID : this.CdtID,
                    SideID : this.sider,
                    NumberLanes : this.lane,
                    NumberLevels : this.level,
                    PositionsLeve : this.pst
                }
                
                _sPackingTunnel.detail(data, this.$fun.getUserID())
                .then((r) => {
                    if (r.status == 200) {
                        this.tunnelInfo= r.data;     
                        this.QrData = this.tunnelInfo.Pallets[0].PalletGroupID;    
                              
                    }
                })
            },
            ShowViewDetails()
			{
				if(this.showMore)
				{
					this.showMore = false;
				}else{
					this.showMore = true;
					// this.GetDollByTable();
				}
				
			},
            printQR() {     
                    
                    var imgAll = document.querySelector("img");
                    
                    var Pagelink = "about:blank";
                    var pwa = window.open(Pagelink, "_new");
                    pwa.document.open();
                    pwa.document.write(
                        "<html><head><scri" +
						"pt>function step1(){\n" +
						"setTimeout('step2()', 10);}\n" +
						"function step2(){window.print();window.close()}\n" +
						"</scri" +
						"pt><style  media='print'> body{font-family: arial} table, th, td {border: 1px solid black;border-collapse: collapse;padding:2px;font-size:9pt;}" + 
						" @media print  { @page { margin-left: 0.5cm; {} </style></head><body onload='step1()'> \n" +
						"<div class='col col-12 justify-center'>",
                    );
                        pwa.document.write(
                            "<div class='col col-6' style='margin: 20px 20px 20px 20px; position:absolute;'><img alt='Scan me!' style='display: block;'  src='" +
                                imgAll.currentSrc +
                                "' />",
                        );
                            
                        pwa.document.write("<b style='font-size: 35px!important; z-index: 2;margin-top: 40px;border-radius: 20px;'>ID: " + 
                        this.tunnelInfo.Pallets[0].PalletGroupID+"</b>"); 
                    
                        pwa.document.write("</div><div style='margin: 30px 0px 0px 410px;'><table colspan='1' border='1'>");
                        pwa.document.write(
                            "<tr><td colspan='2' style='text-align:center;'><b>Sunshine Camaras</b></td>" + 
                            
                            "</tr>"+
                                "<tr><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;AP-FR-213<br>Paleticket de identificación de pallet</td><td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;VERSION:04" +
                            "<br>F. creación:01/07/2024" +
                            "<br>F. última revisión:01/07/2024</td></tr>" +		
                            "<tr><td>Cliente</td><td>" + this.tunnelInfo.Pallets[0].CumNombre + "</td></tr>"+
                            "<tr><td>Marca</td><td>" + this.tunnelInfo.Pallets[0].BransName + "</td></tr>"+
                            "<tr><td>Materia Prima</td><td>" + this.tunnelInfo.Pallets[0].TypeCultiveName + "</td></tr>"+
                            "<tr><td>Variedad</td><td>" + this.tunnelInfo.Pallets[0].VrtDescription + "</td></tr>"+
                            "<tr><td>Trazabilidad</td><td>" + this.tunnelInfo.Pallets[0].TraceabilityID + "</td></tr>"+
                            "<tr><td>Corte</td><td>" + this.tunnelInfo.Pallets[0].TypeCutName + "</td></tr>"+
                            "<tr><td>Presentacion</td><td>" + this.tunnelInfo.Pallets[0].TypePresentationName + "</td></tr>" +
                            "<tr><td>Cajas</td><td>" + this.tunnelInfo.Pallets[0].NumberBoxes + " </td></tr>" + 
                            "<tr><td>Peso (Kg)</td><td>" + this.tunnelInfo.Pallets[0].WeightNetBoxes + " KG.</td></tr>"+
                            "<tr><td>Estado Fianl</td><td>" + this.tunnelInfo.Pallets[0].StateFinalString + " </td></tr>"							
							
					    );
					pwa.document.write("</table></div>");
                
                    pwa.document.write("</div></body></html>");
                    pwa.document.close();
               
				
			},           
          
           

        },
        created(){
            this.detailPallet();
        },
        mounted()
        {

        }
    }
</script>
